<template>
  <div>
    <v-card>
      <v-card-text>
        <v-sheet outlined color="grey" rounded>
          <v-card class="elevation-0 outlined">
            <v-card-text class="pb-2">
        <v-row>
          <v-col>
              <h3 v-if="planCalendar">
                ແຜນ {{planCalendar.plan_name}} - {{planCalendar.date}}
              </h3>
            </v-col>
        </v-row>

        <v-row>
          <v-col>
            <div>
              ລະຫັດລູກຄ້າ: {{routePlanDetail.customer_code}}
            </div>
          </v-col>
          <v-col>
            <div>
              ຊື່: {{routePlanDetail.customer_name}}
            </div>
          </v-col>
          <v-col>
            <div>
              ເບີໂທ: {{routePlanDetail.phone}}
            </div>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <div>
              ບ້ານ: {{routePlanDetail.village_name}}
            </div>
          </v-col>
          <v-col>
            <div>
              ເມືອງ: {{routePlanDetail.district_name}}
            </div>
          </v-col>
          <v-col>
            <div>
              ສະຖານະ: <v-chip :class="getBgColorFunc(routePlanDetail.status)">{{statusLao(routePlanDetail.status)}}</v-chip>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
           ລາຍລະອຽດຍົກເລີກ: <span class="warning--text">{{routePlanDetail.description}}</span>
          </v-col>
          <v-col>
           ຜູ້ຮັບຜິດຊອບ: {{routePlanDetail.created_by_name}}
          </v-col>
        </v-row>
              <v-row>
                <v-col>
                  <p>
                    ສະຖານະແຜນເສັ້ນທາງ: <v-chip :class="getBgColorFunc(routePlanDetail.route_plan_status)">{{statusLao(routePlanDetail.route_plan_status)}}</v-chip>
                  </p>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-sheet>
        <v-row>
        <v-col>
          <p v-if="routePlanDetail.reject_image">ຮູບລາຍງານ</p>
          <v-img :src="routePlanDetail.reject_image"></v-img>
        </v-col>
        </v-row>
      </v-card-text>

    </v-card>
  </div>
</template>

<script>

import { getLaoStatus, getBgColor} from "../../../Helpers/Translate";
export default {
  // mixins: [manage],
  data() {
    return {
      TableLoading:false,
      //Pagination
      planCalendar:{},
      routePlanDetail: {},
    }
  },
  metaInfo() {
    return {
      title: `ລາຍລະອຽດແຜນເວລາຈັດສົ່ງ`,
    }
  },
  methods: {
    Back() {
      this.$router.push({name: 'Calendar'});
    },
    getBgColorFunc(status) {
      return getBgColor(status)
    },
    statusLao(status) {
      return getLaoStatus(status)
    },
    fetchData() {
      this.loading = true;
      this.$axios.get('driver/plan-calendar/' + this.$route.params.id + '/' + this.$route.params.detail_id).then(res => {
        this.routePlanDetail = res.data.detail;
        this.planCalendar = res.data.plan_calendar;
        console.log(this.routePlanDetail);
        this.loading = false;
      }).catch(() => {
        this.loading = false;
      });
    },
  },
  created() {
    this.fetchData();
  }
};
</script>

<style>
</style>