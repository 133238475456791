export const getBgColor = function (status) {
    switch (status) {
        case "pending":
        case "ລໍຖ້າຈັດສົ່ງ":
            return "info";
        case "success":
        case "ສົ່ງສຳເລັດ":
            return "success";
        case "cancel":
        case "ຍົກເລີກ":
            return "warning";
        case "reject":
        case "ປະຕິເສດ":
            return "error";
            case "ໃຊ້ງານຢູ່":
            case "active":
            return "success";
            case "ເຊົາໃຊ້ງານ":
            case "inactive":
            return "error";
        default:
            return status;
    }
}

export const getLaoStatus = function (status) {
    switch (status) {
        case "pending":
            return "ລໍຖ້າຈັດສົ່ງ"
        case "success":
            return "ສົ່ງສຳເລັດ"
        case "cancel":
            return "ຍົກເລີກ"
        case "reject":
            return "ປະຕິເສດ"
        default:
            return status;
    }
}